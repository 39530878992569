<template>
  <div class="Profile">
    <h1 class="text-h5 grey--text text-uppercase">
      Profile
    </h1>
  </div>
</template>

<script>
export default {
  name: "Profile",

  components: {
    //
  },

  mounted() {
    //
  },
};
</script>

<style scoped>
.Profile {
  padding: 20px;
}
</style>
